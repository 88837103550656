import axios, { AxiosRequestConfig } from 'axios'
import moment from 'moment'
import { CouponData, User } from 'types'
import StripeSubscription from 'types/StripeSubscription'
import { AppStore, T } from 'utils'
import { api, responseErrorCheck } from './api'
import Medias from './Medias'

export const timeout10s = 10000
export enum SocialType {
  instagram = 'instagram',
  facebook = 'facebook',
  youtube = 'youtube',
  tiktok = 'tiktok',
  linkedin = 'linkedin',
  pinterest = 'pinterest',
  google = 'google',
  xTwitter = 'x',
}

interface UpdateMethod {
  customerCard: string
  cardNumber: string
  expMonth: number
  expYear: number
  cvc: string
  billings: any
}
interface UpdateInfo {
  billings: {
    name?: string
    surname?: string
    address?: string
    province?: string
    city?: string
    postalCode?: string
    fiscalCode?: string
    businessName?: string
    vatNumber?: string
    sdipec?: string
    coupon?: string
    type?: any
  }
}

export interface ResponsePaymentIntent {
  requiresAction: boolean
  paymentIntentId: string
  client_secret: string
}

export default class Utils {
  static endpoint = '/utils'

  static generateText(data: { argument: string; lenght: number; type: number; voicetone: number }) {
    return api
      .post<string[]>(`${this.endpoint}/generate-text/${AppStore.workspaceId}`, data, {
        timeout: 5000000,
      })
      .then(responseErrorCheck)
  }

  static generateHashtag(data: { argument: string }) {
    return api.post<string[]>(`${this.endpoint}/generate-hashtag`, data).then(responseErrorCheck)
  }

  static settings() {
    return api.get<any>(`${this.endpoint}/settings`)
  }

  // static createStripeCustomer() {
  //   return api.post<any>(`${this.endpoint}/stripe/create-customer`)
  // }

  // static createStripeSubscription(data: any) {
  //   return api.post<StripeSubscription>(`${this.endpoint}/stripe/create-subscription`, data).then(responseErrorCheck)
  // }

  // static updatePaymentMethod(data: UpdateMethod) {
  //   return api.post<any>(`${this.endpoint}/stripe/update-payment-method`, data).then(responseErrorCheck)
  // }

  // static updateInfo(data: UpdateInfo) {
  //   return api.post<any>(`${this.endpoint}/stripe/update-info`, data).then(responseErrorCheck)
  // }

  // static getMySubscription() {
  //   return api.get<any>(`${this.endpoint}/stripe/my-subscription`).then(responseErrorCheck)
  // }

  // static cancelSubscription() {
  //   return api.delete(`${this.endpoint}/stripe/cancel-subscription`).then(responseErrorCheck)
  // }

  // static createPaymentIntent(data: { textQuantity: number; coupon?: string }) {
  //   return api.post<ResponsePaymentIntent>(`${this.endpoint}/stripe/create-session`, data).then(responseErrorCheck)
  // }
  static getFrillUserToken(id) {
    return api.get(`${this.endpoint}/${id.id}`).then(responseErrorCheck)
  }
  static saveOnAirtableOnClick(value) {
    return api.post(`${this.endpoint}/airtable/demo/onClick`, value)
  }
  static saveOnAirtableAnswer(value) {
    return api.post(`${this.endpoint}/airtable/demo/answer`, value)
  }
  static cancelSub(data) {
    return api.post(`${this.endpoint}/airtable/cancelPlan`, data)
  }
  static translate(texts, outputLanguage, inputLanguage?) {
    return api.post(`${this.endpoint}/translate`, { texts, outputLanguage, inputLanguage })
  }

  //se id è disponibile allora va ad aggiornare il campo di id se non è disponibile allora lo va ad creare
  static async airtableThumb(obj: {
    id: string | undefined
    text: string
    thumbUp: boolean
    socialAcc: string
    ArgumentType: string
    toneofVoice: string
    From: string
    toLanguage: string
    generatedTexts: string
    feedbackNotes: string
  }) {
    const req = await api.post(`${this.endpoint}/aritableThumb`, obj)
    return req
  }

  static textGeneratedSave({
    finalText,
    promptText,
    arryText,
    argumentType,
    fromLanguage,
    toLanguage,
    argumentTrasnlated,
    copyResult,
    socialAccont,
    media,
    date,
  }: {
    finalText: string | undefined
    promptText: string | undefined
    arryText: string[] | undefined
    argumentType: string | undefined
    fromLanguage: string | undefined
    toLanguage: string | undefined
    argumentTrasnlated: string | undefined
    copyResult: string[] | undefined
    socialAccont: any
    media: any
    date: any
  }) {
    return api.post(`${this.endpoint}/airtable/textGeneratedData`, {
      finalText,
      promptText,
      arryText,
      argumentType,
      fromLanguage,
      toLanguage,
      argumentTrasnlated,
      email: AppStore.loggedUser.email,
      notTranslatedText: copyResult,
      socialAccont,
      media,
      date,
    })
  }

  static changeLanguage(language?) {
    return api.post(`${this.endpoint}/changeLanguage/${language}`)
  }

  static updateUserEditorCount() {
    return api.post(`${this.endpoint}/airtable/updateUserEditorCount`)
  }

  // static AddFreeTrialDayes(token: string) {
  //   // const trytoken = 'FreeTrialUpdate'
  //   return api.post(`${this.endpoint}/AddFreeTrialDayes/${token}`)
  // }

  static getCoupon(coupon: string): Promise<CouponData> {
    return api.get<CouponData>(`${this.endpoint}/stripe/coupon/${coupon}`).then(responseErrorCheck)
  }
  static ceackIfUserExists(data: { userName: string }) {
    return api.post(`${this.endpoint}/userExistInstagram`, data)
  }
  static async getPrefineryData() {
    return api.post(`${this.endpoint}/getPrefineryData`)
  }

  static async getMetaTags(text?: any) {
    const req = await api.post(`${this.endpoint}/getMetaTags`, text)
    const html = req.data

    const cheerio = require('cheerio')
    const $ = cheerio.load(html)
    const ogDescription = $('meta[property="og:description"]').attr('content') ?? ''
    const ogUrl = $('meta[property="og:url"]').attr('content') ?? ''
    const ogSiteName = $('meta[property="og:site_name"]').attr('content') ?? ''
    const ogImage = $('meta[property="og:image"]').attr('content') ?? ''

    return {
      ogDescription,
      ogUrl,
      ogSiteName,
      ogImage,
    }
  }

  static async test(text?: any) {
    const data = {
      text: text,
    }
    return api.post(`${this.endpoint}/test`, data)
  }

  static async getSiteData(
    url?: string,
    social?: string,
    lenght?: number,
    type?: number,
    voicetone?: number,
    outputLanguage?: string
  ) {
    const datas = JSON.stringify({
      url: url,
      loggedUserId: AppStore.loggedUser._id,
      social,
      voicetone,
      type,
      lenght,
      translate: outputLanguage,
    })

    const config: AxiosRequestConfig = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: 'https://m5wak3l4ruxfvzgx6lpj2th3qi0fvpdi.lambda-url.eu-central-1.on.aws/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: datas,
      timeout: 300000000,
    }

    const { data } = await axios.request(config)
    return data
  }
  static async increseGeneration() {
    const result = await api.post(`${this.endpoint}/increseGeneration`)
    return result.data as User
  }
  static async getBlobData(url: string) {
    const data = {
      url,
    }
    const req = await api.post(`${this.endpoint}/getBlobData`, data)
    return req
  }

  static async getYouTubeCategories(title: string, socialAccountId: string) {
    const data = {
      title,
      socialAccountId,
    }
    const req = await api.post(`${this.endpoint}/categorisRecover`, data)
    return req
  }

  static async getYouTubePlaylists(title: string, socialAccountId: string) {
    const data = {
      title,
      socialAccountId,
    }
    const req = await api.post(`${this.endpoint}/playlistRecover`, data)
    return req
  }

  static generateRandomUIDKey() {
    return Math.random().toString(36).substring(2, 16)
  }

  static async fetchImageAsBlob(url: string): Promise<Blob> {
    const response = await fetch(url)
    const blob = await response.blob()
    return blob
  }

  static async saveImageRemote(url: string, name: string, path: string) {
    const imageBlob = await Utils.fetchImageAsBlob(url)
    return Utils.saveImageRemoteBlob(imageBlob, name, path)
  }

  static async saveImageRemoteBlob(blob: Blob, name: string, path: string, isPublic?: boolean) {
    try {
      const formData = new FormData()
      formData.append('file', blob, name)
      formData.append('name', name)
      formData.append('path', path)
      formData.append('type', 'image/' + name.split('.').pop())

      // console.log('formData', formData)
      const media: any = await Medias.createFile(formData, undefined, isPublic)
      try {
        console.log('media', media)
        const mediaUrl = await Medias.getUrl(media?.data)
        media.data.url = mediaUrl?.data
      } catch (e) {
        media.data = { url: media.presignedUrl }
      }
      // console.log('mediaUrl', mediaUrl)
      return media
    } catch (e) {
      console.error(e)
    }
  }
}

/**** Enabling Commandbar ****/
export const initAndBootCommandBar = () => {
  window.CommandBar.boot(AppStore.loggedUser._id ?? 'id_not_found')
  window.CommandBar.setTheme(AppStore.darkTheme ?? false ? 'dark' : 'light')
}

/**
 * Funzione per controllare se due array sono uguali indipendentemente dall'ordine degli elementi
 * @param arr1 Array di elementi
 * @param arr2 Array di elementi
 * @returns True se gli array sono uguali, altrimenti false
 */
export const checkIfArrayAreEqual = (arr1: any[], arr2: any[]): boolean => {
  // Ordinare entrambi gli array
  const sortedArray1 = arr1.sort()
  const sortedArray2 = arr2.sort()

  // Confrontare gli array ordinati
  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false
    }
  }

  return true
}

/**
 * Funzione per controllare se un array contiene tutti gli elementi di un altro array
 * @param arr1 Array di elementi
 * @param arr2 Array di elementi
 * @returns True se arr1 contiene tutti gli elementi di arr2, altrimenti false
 */
export const containsArray = (arr1: any[], arr2: any[]): boolean => {
  // Check for empty arrays
  if (arr1.length === 0 || arr2.length === 0) {
    return false
  }

  // Use some() to check if all elements of arr2 are present in arr1
  // with both exact and loose comparison
  return arr2.every((element) => arr1.some((item) => element === item || element.toString() === item.toString()))
}

/**
 * Controlla se l'elemento cliccato è esterno all'elemento passato
 * @param currentElement Current element
 * @param elementToCheck Element to check
 * @returns  True se l'elemento cliccato è esterno all'elemento passato, altrimenti false
 */
export const isExternalElementClicked = (currentElement, elementToCheck: Element): boolean => {
  return !currentElement?.contains(elementToCheck)
}

/**
 * Funzione per dato un numero ritorna il mese corrispondente
 * @param month number of the month (0-11)
 * @returns the month in the correct language
 */
export const formatMonth = (month: number) => {
  const months = [
    T.date.months.january,
    T.date.months.february,
    T.date.months.march,
    T.date.months.april,
    T.date.months.may,
    T.date.months.june,
    T.date.months.july,
    T.date.months.august,
    T.date.months.september,
    T.date.months.october,
    T.date.months.november,
    T.date.months.december,
  ]

  return months[month]
}

/**
 *  Funzione che restituisce il nome del giorno della settimana dato il giorno, il mese e l'anno in numeri
 * @param day numero del giorno del mese
 * @param month numero del mese (0-11)
 * @param year anno
 * @returns il nome del giorno della settimana tradotto
 */
export const getDayName = (day: number, month: number, year: number): string => {
  // Create a Date object with the provided values
  const date = new Date(year, month, day)

  // Get the weekday number (0 = Sunday, 1 = Monday, ...)
  const dayNumber = date.getDay()

  const weekdayNames = T.calendarPage.weekdaysMin

  // Return the weekday name corresponding to the obtained number
  return weekdayNames[dayNumber]
}

/**
 * Function to calculate the number of days in a given month
 * @param month The month (0-11)
 * @param year The year
 * @returns The number of days in the month
 */
export const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month + 1, 0).getDate()
}

/**
 * Function to calculate the number of days in the previous month
 * @param month The current month (0-11)
 * @param year The current year
 * @returns The number of days in the previous month
 */
export const daysInPreviousMonth = (month: number, year: number) => {
  const prevMonth = month === 0 ? 11 : month - 1
  const prevYear = month === 0 ? year - 1 : year
  return getDaysInMonth(prevMonth, prevYear)
}

/**
 * Function to format the total of post of the day
 * @param total the total of post of the day
 * @param maxNumber the max number of post to show
 * @returns the formatted total
 */
export const formatMaxNumber = (total: number, maxNumber: number) => {
  if (total > maxNumber) return `${maxNumber}+`

  return total
}

/**
 * Funzione per ritornare i dati dei post di un giorno per popolare la cella del calendario
 * @param date  moment.Moment preso dalla date del calendario
 * @returns il primo risultato del filtro per data
 */
export const getPostsByDate = (date: moment.Moment, arrayToFilter: any[]) => {
  const filteredPosts = arrayToFilter.filter((post) => {
    return moment(post.date).isSame(date, 'day')
  })

  return filteredPosts[0]
}

/**
 *  Funzione per ottenere il dominio da un URL
 * @param url  URL da cui ottenere il dominio
 * @returns  il dominio dell'URL
 */
export const getDomain = (url: string) => {
  const regex = /:\/\/([^/]+)/i
  const match = url?.match(regex)
  if (match) {
    return match[1]
  } else {
    return null
  }
}

export const compareAndMergeArrays = (firstArray: any[], secondArray: any[]) => {
  const updatedRules = firstArray.filter((rule) => !secondArray.includes(rule))
  const addedRules = secondArray.filter((rule) => !firstArray.includes(rule))
  return [...updatedRules, ...addedRules]
}

/**
 *  Funzione per ottenere se TOT minuti sono passati dalla data passata
 * @param date Data sulla quale fare il controllo
 * @param totMinutes Minuti da controllare
 * @returns true se sono passati TOT minuti, altrimenti false
 */
export const hasTotMinutesPassed = (date: Date, totMinutes: number) => {
  const totMinutesInMs = totMinutes * 60 * 1000
  const oldDate = moment(date)
  const now = moment()

  return now.diff(oldDate, 'milliseconds') > totMinutesInMs
}
