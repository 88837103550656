import RemoveDemoScript from 'RemoveDemoScript'
import { routerCollab, routerLogged, routerNoLogged } from 'Router/Router'
import Auth from 'api/Auth'
import Utils, { initAndBootCommandBar } from 'api/Utils'
import Workspaces from 'api/Workspaces'
import { AnimatedPlaceholderLogo, Box, Icons, Menu } from 'components'
import { Modal } from 'components/Modal/Modal'
import 'index.css'
import { useCallback, useEffect, useState } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ModalType, ParamsOptions, User } from 'types'
import { AppStore, convertDateToUnixTimestamp, generateIntercomHash, showError } from 'utils'
import { history } from 'utils/router'

// Inizialize MUI license
import { LicenseInfo } from '@mui/x-license-pro'
import { init as initC } from 'commandbar'
import { requestUpgradeModal } from 'components/HeaderUpgradeButton'
import ConfirmEmailComponent from 'pages/confirmEmail/ConfirmEmailComponent'
import { useMenuNavigationStore } from 'stores/MenuNavigationStore'
import { useModalNavigationStore } from 'stores/ModalNavigationStore'
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_APY_KEY ?? '')
initC(process.env.REACT_APP_COMMAND_BAR ?? 'fc81c84b', { environment: 'qa', debug: false })

const App: React.FC = () => {
  // SCALE VARIABLE
  const scaleBase = 1920
  let router
  const [loggedUser, setLoggedUser] = useState<User | undefined>(AppStore.loggedUser)
  const [loading, setloading] = useState(true)
  const [modals, setmodals] = useState<ModalType[]>([])
  const [updateS, updateState] = useState<any>()
  const forceUpdate = useCallback(() => updateState({}), [])
  let timer
  const initZoom = (window.innerWidth / scaleBase) * 100

  /* ----------------- ZUSTAND  */
  const { navigatePage } = useModalNavigationStore((s) => ({
    navigatePage: s.navigatePage,
  }))

  // Use effect per permette l'utilizzo di "navigate" all'interno di una modale
  // Logica -> se navigatePage è diverso da '' allora naviga alla pagina, quindi utilizzare "setNavigatePage" con il path in cui navigare, una volta navigato il valore viene resettato e così si può riutilizzare più volte nello stesso path
  useEffect(() => {
    if (navigatePage !== '') {
      //Naviga
      router.navigate(navigatePage)

      // Aggiorna lo stato per il menu
      const menuValue = navigatePage.split('/')[1]
      useMenuNavigationStore.getState().setLocationPage(menuValue)

      //Resetta il valore
      useModalNavigationStore.getState().setNavigatePage('')
    }
  }, [navigatePage])

  // const bodyElement = document.getElementById('body')!
  const rootElement = document.getElementById('root')!
  const addClassname = (zoom) => {
    const style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `.ant-select-dropdown {
      transform: scale(${zoom}%);
    }

    .ant-popover {
      transform: scale(${zoom}%);
      visibility: hidden;
    }
    `
    document.head.appendChild(style)
  }

  useEffect(() => {
    if (!localStorage.getItem('lastSocialCheck')) {
      localStorage.setItem('lastSocialCheck', new Date().toISOString())
      localStorage.setItem('socialCheck', 'true')
    }
    addClassname(initZoom)
    rootElement.style.cssText = `
      transform: scale(${initZoom}%, ${initZoom}%);
      transform-origin: left top;
      max-width: ${100 * (100 / initZoom)}vw;
      min-width: ${100 * (100 / initZoom)}vw;
      max-height: ${100 * (100 / initZoom)}vh;
      min-height: ${100 * (100 / initZoom)}vh;
    `

    window.addEventListener('resize', () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        const zoom = (window.innerWidth / scaleBase) * 100
        AppStore.zoom = zoom
        rootElement.style.cssText = `
          transform: scale(${zoom}%, ${zoom}%);
          transform-origin: left top;
          max-width: ${100 * (100 / zoom)}vw;
          min-width: ${100 * (100 / zoom)}vw;
          max-height: ${100 * (100 / zoom)}vh;
          min-height: ${100 * (100 / zoom)}vh;
        `
        addClassname(zoom)

        setTimeout(() => {
          AppStore.resizeCallbacks.forEach(({ callback, id }) => {
            try {
              callback()
            } catch (error) {
              console.error('Error in resize callback: ' + id, error)
            }
          })
        }, 200)
      }, 200)
    })

    setLoggedUser(AppStore.loggedUser ?? undefined)

    const init = async () => {
      if (history.location.pathname === '/token') {
        // console.log('setToken:init-1')
        await AppStore.setToken(history.location.search.substring(14))
        router.navigate('/dashboard')
      }

      if (history.location.pathname.includes('confirmUser')) {
        try {
          const token = await Auth.confirmUser(history.location.search.substring(11))
          // console.log('setToken:init-2')
          await AppStore.setToken(token)
          router.navigate('/dashboard')
        } catch (error) {
          // console.log('setToken:init-3', error)
          await AppStore.setToken()
          showError(error)
        }
      }

      if (history.location.pathname.includes('acceptNewEmail')) {
        try {
          const token = await Auth.acceptNewEmail(history.location.search.substring(7))
          await AppStore.setToken(token)
          if (history.location.pathname.includes('Settings')) history.push('/settings/account?newEmail=true')
        } catch (error) {
          await AppStore.setToken()
          showError(error)
        }
      }

      paramsOptions()
      await AppStore.init()

      try {
        const url = new URL(window.location.href)
        const tokenExtend = url.searchParams.get('extend')

        const referral = url.searchParams.get('r')
        if (referral !== null) {
          const extended = (AppStore.referralCode = referral)
        }

        // if (tokenExtend !== null) {
        //   const extended = await Utils.AddFreeTrialDayes(tokenExtend)
        // }
      } catch (e) {
        console.error(e)
      }

      //@ts-ignore
      if (AppStore.darkTheme) import('theme-dark.css')
      //@ts-ignore
      if (!AppStore.darkTheme) import('theme-light.css')

      setloading(false)
    }
    init()
  }, [])

  useEffect(() => {
    async function init() {
      AppStore.openModal = (modal: ModalType) => {
        // verify if the modal is already open
        if (modals.find((m) => m.id === modal.id)) return
        setmodals([
          ...modals,
          { ...modal, closeIcon: <Icons.sizerClose fill={AppStore.theme.o.black} width={32} height={32} /> },
        ])
        addBlurEffect()
      }
      AppStore.closeModal = (id: string) => {
        const modalIndex = modals.findIndex((modal) => modal.id === id)

        if (modalIndex === -1) return
        //remove modal from modals
        //call afterClose if it exists
        modals[modalIndex]?.afterClose?.()
        const modalsRemoved = [...modals.slice(0, modalIndex), ...modals.slice(modalIndex + 1)]
        setmodals(modalsRemoved)
        if (modalsRemoved.length === 0) {
          removeBlurEffect()
        }
      }
      AppStore.closeAllModal = () => {
        //call afterClose if it exists
        modals.forEach((modal) => modal.afterClose?.())

        setmodals([])
        removeBlurEffect()
      }
      AppStore.reloadRouting = forceUpdate
    }

    init()
    return () => {}
  }, [forceUpdate, modals])

  const addBlurEffect = () => {
    //get element with id menu-container and pages-container
    const menuContainer = document.getElementById('menu-container')
    const pagesContainer = document.getElementById('pages-container')
    //add blur effect to them
    menuContainer?.classList.add('blurred')
    pagesContainer?.classList.add('blurred')
  }

  const removeBlurEffect = () => {
    //get element with id menu-container and pages-container
    const menuContainer = document.getElementById('menu-container')
    const pagesContainer = document.getElementById('pages-container')
    //remove blur effect to them
    menuContainer?.classList.remove('blurred')
    pagesContainer?.classList.remove('blurred')
  }

  const paramsOptions = () => {
    const params = new URLSearchParams(window.location.search)
    const options: ParamsOptions = {}
    if (params.has('redirect')) {
      const redirect = params.get('redirect')
      if (redirect === 'logIn' || redirect === 'signUp') {
        options.whereToRedirect = redirect
      } else {
        window.location.href = params.get('redirect') ?? '/'
      }
    }

    if (params.has('email')) {
      options.defaultEmail = params.get('email') ?? ''
    }

    AppStore.parampsOption = options
  }

  const confirmMailModal = () => {
    AppStore.openModal({
      id: 'preview-modal',
      body: <ConfirmEmailComponent />,
      style: {
        minHeight: 876,
        minWidth: 696,
      },
    })
  }

  const checkIfUserIsLogged = async () => {
    return new Promise<boolean>(async (resolve, reject) => {
      if (localStorage.getItem('token')) {
        try {
          const url = new URL(window.location.href)
          const tempToken = url.searchParams.get('tempToken') ?? ''
          const workspace = url.searchParams.get('workspaceId') ?? ''
          const userId = url.searchParams.get('userId') ?? undefined

          if (!userId) {
            showError('Link di invito non valido')
            reject('Unauthorized')
          }
          const localUserId = localStorage.getItem('userId')
          if (!localUserId) resolve(false)
          if (localUserId === userId) {
            await Workspaces.acceptColaboration(tempToken, workspace)
          } else {
            showError('Utente non autorizzato')
            reject('Unauthorized')
          }
          setTimeout(() => {
            localStorage.setItem('workspaceId', workspace)
            window.location.href = '/dashboard'
            resolve(true)
          }, 500)
        } catch (error) {
          console.error(error)
        }
        resolve(false)
      }
    })
  }

  useEffect(() => {
    try {
      if (!loggedUser) {
        setLoggedUser(AppStore.loggedUser ?? undefined)

        // if (!!AppStore.loggedUser && !AppStore.userValidated) {
        //   confirmMailModal()
        // }
      }
    } catch (error) {
      console.error(error)
    }

    // ------ ------ Intercom Chat ------ ------ //
    if (AppStore.loggedUser) {
      try {
        const loggedUserCreatedAt = convertDateToUnixTimestamp(AppStore.loggedUser.created_at)
        initAndBootCommandBar()

        // @ts-ignore
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'midhmdek',
          name: AppStore.loggedUser.name ?? '', // Full name
          email: AppStore.loggedUser.email ?? '', // the email for your user
          user_id: AppStore.loggedUser._id ?? '', // User ID
          user_hash: generateIntercomHash(), // Generated hash for the user
          created_at: loggedUserCreatedAt ?? '', // SignUp date as a Unix timestamp
          language_override: AppStore.loggedUser.language, // Set the language of intercom
        })
      } catch (error) {}
    }
    checkUserLogged()
  })

  const checkUserLogged = async () => {
    const location = window.location.pathname

    if (location === '/createColabAccount' || location === '/acceptColaboration') {
      // console.log('checkUserLogged', location)
      try {
        const isUserLogged = await checkIfUserIsLogged()
        if (isUserLogged) {
          router = createBrowserRouter(routerLogged)
        } else {
          router = createBrowserRouter(location === '/createColabAccount' ? routerCollab : routerNoLogged)
        }
      } catch (error) {
        console.error('errrr ', error)
        localStorage.clear()
        history.go(0)
      }
    }
  }

  if (!loggedUser) {
    router = createBrowserRouter(routerNoLogged)
  } else if (loggedUser) {
    router = createBrowserRouter(routerLogged)
  }

  // da riguardare con l'aggiornamento  per history.replace ( path senza / finale)
  useEffect(() => {
    if (window.location.pathname === '/') {
      //  window.location.pathname = window.location.pathname = '/'
    } else if (window.location.pathname.endsWith('/'))
      window.location.pathname = window.location.pathname.substring(0, window.location.pathname.length - 1)
  }, [window.location.pathname])

  /*** Check per modale di upgrade */
  useEffect(() => {
    const checkHash = () => {
      setShowUpgradeModal(window.location.hash === '#upgrade')
    }

    window.addEventListener('hashchange', checkHash)
    checkHash() // Check on initial load

    return () => window.removeEventListener('hashchange', checkHash)
  }, [])

  const setShowUpgradeModal = (show: boolean) => {
    show && requestUpgradeModal()
  }

  return (
    <>
      {loading && <AnimatedPlaceholderLogo />}
      {!loading && (
        <>
          {loggedUser && (
            <>
              <RemoveDemoScript />
              <Menu router={router} />
              <Box flex id="pages-container">
                <RouterProvider router={router} />
              </Box>
            </>
          )}

          {!loggedUser && <RouterProvider router={router} />}
        </>
      )}
      {modals.map((modal) => {
        if ('modal' in modal) return modal.modal

        return (
          <Modal
            centered
            {...modal}
            afterClose={undefined}
            visible={true}
            key={modal.id}
            getContainer={document.getElementById('root')!}
            onCancel={(e) => {
              let doRestOfTheCode = true
              if (e.type === 'keydown' && modal?.TypeEscapeNull) {
                const IsCosable = document.getElementById('modalIsNotClosable')
                if (IsCosable !== null) doRestOfTheCode = false
              }

              if (doRestOfTheCode) {
                modal.visible = false
                setmodals(modals.filter((m) => m.id !== modal.id))
                removeBlurEffect()
                modal?.onCancel?.(e)
              }
            }}
          >
            {typeof modal.body === 'function' ? modal.body() : modal.body}
          </Modal>
        )
      })}
    </>
  )
}

export default App
